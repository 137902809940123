import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Axios, { AxiosHeaders } from "axios";
import { errorNotification } from "../utils/Notifications";
import { logger } from "../utils/Logger";

const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

async function extractMessageFromBlob(blob: Blob): Promise<string> {
  const text = await blob.text();
  try {
    const parsed = JSON.parse(text);
    return parsed.message || text;
  } catch (_error) {
    return text;
  }
}

export const useApiPrivateFileRequest = () => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  const call = async (
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    params?: Record<string, unknown>,
    body?: any,
    headers?: AxiosHeaders,
  ) => {
    try {
      setIsLoading(true);
      const response = await axios.request<Blob>({
        url: url,
        method: method,
        params: params,
        data: body,
        headers: {
          ...headers,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
        responseType: "blob",
        // This is needed to prevent Axios from throwing an error on non-200 responses
        validateStatus: (_) => true,
      });

      let responseMessage: string;

      switch (response.status) {
        case 200: {
          logger.debug(response.headers);
          const filename = response.headers["content-disposition"]
            .split(";")
            .find((s: any) => s.includes("filename"))
            .split("=")[1]
            .replaceAll('"', "");
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            }),
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          break;
        }
        case 401:
          logout();
          break;
        case 400:
          responseMessage = await extractMessageFromBlob(response.data);
          errorNotification("Bad request", responseMessage);
          break;
        case 404:
          responseMessage = await extractMessageFromBlob(response.data);
          errorNotification(
            "Resource requested was not found",
            responseMessage,
          );
          break;
        default:
          responseMessage = await extractMessageFromBlob(response.data);
          errorNotification("Unexpected server response", responseMessage);
          break;
      }
    } catch (error) {
      errorNotification(
        "Unexpected error",
        "Something went wrong while sending the request",
      );
      logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, call };
};
