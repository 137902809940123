import { UserUpdateType } from "../enums/UserUpdateType";
import { useSignalRConnection } from "@/shared/hooks/userSignalRConnection";

export const useUserUpdates = (
  userId: string,
  callback: (type: UserUpdateType, data: any) => void,
): void => {
  useSignalRConnection({
    hubUrl: "/userUpdatesHub",
    channelName: `${userId}:user`,
    logPrefix: " for User",
    handleMessage: (name: string, data: string) => {
      callback(name as UserUpdateType, JSON.parse(data));
    },
    dependencies: [userId],
  });
};
