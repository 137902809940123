import { useGetDefaultPlaybook } from "../api/useGetDefaultPlaybook";
import { useEffect, useState } from "react";
import PlaybookStepsManagement from "../../playbook/components/PlaybookStepsManagement";
import { PlaybookStepShort } from "../../playbook/model/Playbook";
import { useCreateDefaultPlaybook } from "../api/useCreateDefaultPlaybook";
import { useUpdateDefaultPlaybook } from "../api/useUpdateDefaultPlaybook";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Textarea } from "@/components/ui/textarea";
import { Wand2 } from "lucide-react";
import { useGeneratePlaybookDescription } from "@/features/playbook/api/useGeneratePlaybookDescription";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  errorNotification,
  successNotification,
} from "@/shared/utils/Notifications";

interface EditPlaybookModalProps {
  opened: boolean;
  close: () => void;
  playbookId?: string;
}

export default function EditPlaybookModal({
  opened,
  close,
  playbookId,
}: EditPlaybookModalProps) {
  const { defaultPlaybook, getDefaultPlaybook, setDefaultPlaybook } =
    useGetDefaultPlaybook();
  const [steps, setSteps] = useState<PlaybookStepShort[]>([]);
  const [newPlaybookTitle, setNewPlaybookTitle] = useState("");
  const [newPlaybookDescription, setNewPlaybookDescription] = useState("");
  const { createPlaybook } = useCreateDefaultPlaybook();
  const { updatePlaybook } = useUpdateDefaultPlaybook();
  const { generatePlaybookDescription, isLoading: isGeneratingDescription } =
    useGeneratePlaybookDescription();

  useEffect(() => {
    if (opened && playbookId) {
      getDefaultPlaybook(playbookId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbookId, opened]);

  useEffect(() => {
    if (!defaultPlaybook) return;
    setNewPlaybookTitle(defaultPlaybook.title);
    setNewPlaybookDescription(defaultPlaybook.description || "");
    setSteps(defaultPlaybook.steps);
  }, [defaultPlaybook]);

  const handleSaveClick = async () => {
    const updatedSteps = steps.map((step, index) => ({
      ...step,
      order: index,
    }));
    if (!defaultPlaybook) {
      await createPlaybook(
        newPlaybookTitle,
        newPlaybookDescription,
        updatedSteps,
      );
    } else {
      await updatePlaybook(
        defaultPlaybook.id,
        newPlaybookTitle,
        newPlaybookDescription,
        updatedSteps,
      );
    }

    onClose();
  };

  const onClose = () => {
    setDefaultPlaybook(null);
    setNewPlaybookTitle("");
    setNewPlaybookDescription("");
    setSteps([]);
    close();
  };

  const reorder = (args: { from: number; to: number }) => {
    setSteps((prev) => {
      const newSteps = [...prev];
      const [removed] = newSteps.splice(args.from, 1);
      newSteps.splice(args.to, 0, removed);
      return newSteps;
    });
  };

  const handleGenerateDescription = async () => {
    if (!playbookId) {
      errorNotification(
        "Error",
        "Please save the playbook first before generating a description.",
      );
      return;
    }

    try {
      const generatedDescription = await generatePlaybookDescription(
        playbookId,
        true,
      );
      if (generatedDescription) {
        setNewPlaybookDescription(generatedDescription);
        successNotification(
          "Success",
          "Playbook description has been generated.",
        );
      }
    } catch {
      errorNotification(
        "Error",
        "There was an error generating the description. Please try again.",
      );
    }
  };

  return (
    <Dialog open={opened} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Edit Playbook</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <Input
            value={newPlaybookTitle}
            placeholder="Please enter a valid title"
            className={
              newPlaybookTitle.trim() === "" ? "border-destructive" : ""
            }
            autoFocus
            onChange={(event) => setNewPlaybookTitle(event.currentTarget.value)}
          />

          {/* Description section */}
          <div>
            <div className="flex items-center justify-between mb-2">
              <h5 className="font-medium">Description</h5>
              <Button
                type="button"
                variant="ghost"
                size="sm"
                onClick={handleGenerateDescription}
                disabled={isGeneratingDescription}
                className="text-gray-600 hover:text-gray-900"
              >
                <Wand2
                  className={`h-3.5 w-3.5 mr-1.5 ${isGeneratingDescription ? "animate-spin" : ""}`}
                />
                {isGeneratingDescription ? "Generating..." : "Generate"}
              </Button>
            </div>
            <Textarea
              value={newPlaybookDescription}
              onChange={(event) =>
                setNewPlaybookDescription(event.target.value)
              }
              placeholder="Enter a description for this playbook"
              className="text-sm px-5 py-4 min-h-[100px] resize-none"
            />
          </div>

          <div>
            <h5 className="font-medium mb-2">Steps</h5>
            <ScrollArea className="h-[400px] pr-4">
              <PlaybookStepsManagement
                playbookSteps={defaultPlaybook?.steps || []}
                draggableSteps={steps}
                setState={setSteps}
                reorder={reorder}
                playbookId={defaultPlaybook?.id ?? ""}
              />
            </ScrollArea>
          </div>
          <div className="flex justify-end">
            <Button onClick={handleSaveClick}>Save</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
