import * as signalR from "@microsoft/signalr";
import { useEffect } from "react";
import { logger } from "../utils/Logger";
import { useAuth0 } from "@auth0/auth0-react";

const PING_TIMEOUT = 3000;
const PING_ERROR_MESSAGE = "Ping timeout";
const CONNECTION_TIMEOUT = 30000; // 30 seconds

interface SignalRConfig {
  hubUrl: string;
  channelName: string;
  logPrefix?: string;
  handleMessage: (name: string, data: string) => void;
  dependencies?: any[];
}

export const useSignalRConnection = ({
  hubUrl,
  channelName,
  logPrefix = "",
  handleMessage,
  dependencies = [],
}: SignalRConfig): void => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${import.meta.env.VITE_API_BASE_URL}${hubUrl}`, {
        withCredentials: false,
        accessTokenFactory: getAccessTokenSilently,
        transport:
          signalR.HttpTransportType.WebSockets |
          signalR.HttpTransportType.ServerSentEvents |
          signalR.HttpTransportType.LongPolling,
        skipNegotiation: false,
      })
      .withAutomaticReconnect([0, 2000, 5000, 10000, 20000]) // Retry with increasing delays
      .configureLogging(signalR.LogLevel.Error) // Temporarily increase logging level
      .build();

    connection.serverTimeoutInMilliseconds = CONNECTION_TIMEOUT;
    connection.keepAliveIntervalInMilliseconds = 15000; // 15 seconds

    const startConnection = async () => {
      if (connection.state === signalR.HubConnectionState.Disconnected) {
        try {
          await connection.start();
          logger.debug(`SignalR${logPrefix} connected`);
          await connection.invoke("JoinGroup", channelName);
        } catch (error) {
          logger.error(`SignalR${logPrefix} connection error: `, error);
        }
      }
    };

    startConnection();

    connection.on("ReceiveMessage", handleMessage);
    connection.onclose(() => {
      logger.debug(`SignalR${logPrefix} connection closed. Reconnecting...`);
      setTimeout(startConnection, 3000); // Retry after 3 seconds
    });

    const pingWithTimeout = async (timeoutMs: number) => {
      return Promise.race([
        connection.invoke("Ping"),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error(PING_ERROR_MESSAGE)), timeoutMs),
        ),
      ]);
    };

    const checkConnection = async () => {
      if (connection.state === signalR.HubConnectionState.Connected) {
        try {
          await pingWithTimeout(PING_TIMEOUT);
        } catch (error) {
          if (error instanceof Error && error.message === PING_ERROR_MESSAGE) {
            await connection.stop();
            await startConnection();
          }
        }
      } else if (connection.state === signalR.HubConnectionState.Disconnected) {
        await startConnection();
      }
    };

    const intervalId = setInterval(checkConnection, PING_TIMEOUT);

    return () => {
      clearInterval(intervalId);
      connection.off("ReceiveMessage", handleMessage);
      if (connection.state === signalR.HubConnectionState.Connected) {
        connection
          .invoke("LeaveGroup", channelName)
          .then(() => {})
          .catch((error) => {
            logger.error(`Failed to leave group${logPrefix}: `, error);
          })
          .finally(() => {
            connection.stop();
          });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
};
