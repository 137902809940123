import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { Playbook, PlaybookStepShort } from "../../playbook/model/Playbook";

export const useCreateDefaultPlaybook = () => {
  const {
    data: createdPlaybook,
    call,
    isLoading: isCreating,
    isFailed: isCreateFailed,
  } = useApiPrivateRequest<Playbook>((response) => new Playbook(response));

  const createPlaybook = async (
    title: string,
    description: string,
    steps: PlaybookStepShort[],
  ) => {
    await call(`/admin/playbook`, "POST", undefined, {
      title,
      description,
      steps,
    });
  };

  return {
    createdPlaybook,
    createPlaybook,
    isCreating,
    isCreateFailed,
  };
};
