import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useImprovePlaybookStep = () => {
  const {
    data: improvedPrompt,
    call,
    isLoading,
    isFailed,
  } = useApiPrivateRequest<string>((response) => response);

  const improvePlaybookStep = async (
    playbookId: string,
    stepId: string,
    improvementDescription?: string,
    isDefault: boolean = false,
  ) => {
    const endpoint = isDefault
      ? "/generation/improveDefaultPlaybookStep"
      : "/generation/improvePlaybookStep";

    return call(endpoint, "POST", undefined, {
      playbookId,
      stepId,
      improvementDescription,
    });
  };
  return {
    improvedPrompt,
    improvePlaybookStep,
    isLoading,
    isFailed,
  };
};
