import { UserUpdateType } from "@/shared/enums/UserUpdateType";
import { playbookCompleteNotification } from "@/shared/utils/Notifications";
import { ReactElement } from "react";
import { useUserUpdates } from "@/shared/hooks/useUserUpdates";
import { useAuth0 } from "@auth0/auth0-react";
import { Outlet } from "react-router-dom";

export default function UserUpdatesComponent(): ReactElement {
  const { user } = useAuth0();

  const callback = (type: UserUpdateType, data: any) => {
    switch (type) {
      case UserUpdateType.PlaybookFinished:
        if (!window.location.href.includes(data.chatId))
          playbookCompleteNotification(
            data.chatId,
            data.playbookTitle,
            data.isSuccess,
          );
    }
  };

  useUserUpdates(user!.sub!, callback);

  return <Outlet />;
}
