import { useApiPrivateRequest } from "@/shared/hooks/useApi";

export const useGeneratePlaybookDescription = () => {
  const {
    data: generatedDescription,
    call,
    isLoading,
    isFailed,
  } = useApiPrivateRequest<string>((response) => response);

  const generatePlaybookDescription = async (
    playbookId: string,
    isDefault: boolean = false,
  ) => {
    const endpoint = isDefault
      ? "/generation/generateDefaultPlaybookDescription"
      : "/generation/generatePlaybookDescription";

    return call(endpoint, "POST", undefined, {
      playbookId,
    });
  };
  return {
    generatedDescription,
    generatePlaybookDescription,
    isLoading,
    isFailed,
  };
};
