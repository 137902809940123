import { useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../../shared/utils/ResponsiveStyles";
import {
  IconFileTypePdf,
  IconFileTypeDoc,
  IconFileTypePpt,
  IconFileTypeXls,
  IconFileTypeHtml,
  IconFile,
  Icon,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { UserContentType } from "@/shared/enums/UserContentType";
import { useGetOneChatUserContent } from "@/features/sources/api/useGetOneChatUserContent";
import { useGetPresignedUrl } from "../../../sources/api/useGetPresignedUrl";
import { PdfViewer } from "../../../sources/components/PdfViewer";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import ScrollableTextWithHighlighting from "@/shared/components/scrollable-text-highlighting/ScrollableTextWithHighlighting";
import { cn } from "@/lib/utils";

const FILE_TYPE_ICON_MAP: Record<UserContentType, Icon> = {
  [UserContentType.Pdf]: IconFileTypePdf,
  [UserContentType.Excel]: IconFileTypeXls,
  [UserContentType.Word]: IconFileTypeDoc,
  [UserContentType.PowerPoint]: IconFileTypePpt,
  [UserContentType.Html]: IconFileTypeHtml,
};

export const FileTypeIcon = ({
  type,
  isLargeScreen,
}: {
  type?: UserContentType;
  isLargeScreen?: boolean;
}) => {
  if (!type || !FILE_TYPE_ICON_MAP[type])
    return <IconFile className="text-primary" size={isLargeScreen ? 20 : 18} />;

  const IconComponent = FILE_TYPE_ICON_MAP[type];
  return (
    <IconComponent className="text-primary" size={isLargeScreen ? 20 : 18} />
  );
};

interface SidebarUserContentProps {
  chatId: string;
  contentId: string;
  chunkText?: string;
  pageNumber?: number;
  boundingBox?: {
    xMin: number;
    yMin: number;
    xMax: number;
    yMax: number;
  };
}

export default function SidebarUserContent({
  chatId,
  contentId,
  chunkText,
  pageNumber,
  boundingBox,
}: SidebarUserContentProps) {
  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);
  const { userContent, getUserContent } = useGetOneChatUserContent();
  const [open, setOpen] = useState(false);
  const {
    presignedUrl,
    getPresignedUrl,
    isLoading: isLoadingUrl,
  } = useGetPresignedUrl();

  useEffect(() => {
    getUserContent(chatId, contentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (userContent?.type === UserContentType.Pdf && !userContent.url) {
      getPresignedUrl(chatId, contentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContent?.type, chatId, contentId]);

  if (!userContent) {
    return (
      <p className="text-sm font-bold leading-relaxed break-words">
        Source document not found.
      </p>
    );
  }

  const isLoading = isLoadingUrl;

  return userContent ? (
    <div className="h-full flex flex-col overflow-hidden">
      {isLoading && <CenterLoader />}
      {userContent.type !== UserContentType.Pdf || userContent.url ? (
        <>
          <div className="flex flex-col gap-2 px-3">
            <div className="flex items-center gap-1.5">
              <FileTypeIcon
                type={userContent.type}
                isLargeScreen={isLargeScreen}
              />
              <span className="flex-1 text-xs font-medium text-primary whitespace-normal break-words leading-normal">
                {userContent.title}
              </span>
              {userContent.url && (
                <a
                  href={userContent.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:text-primary/80 underline text-xs"
                >
                  [Link]
                </a>
              )}
            </div>
            {chunkText && (
              <p className="text-xs leading-normal break-words">{chunkText}</p>
            )}
          </div>
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent
              className={cn(
                "max-w-[320px] sm:max-w-[646px] lg:max-w-[910px] xl:max-w-[1200px]",
                "p-0",
              )}
            >
              <DialogHeader>
                <DialogTitle className="text-sm font-bold px-4 py-2">
                  <div className="flex items-center gap-4">
                    <span>{userContent.title}</span>
                    {userContent.url && (
                      <a
                        href={userContent.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary hover:text-primary/80 underline"
                      >
                        [Link to original]
                      </a>
                    )}
                  </div>
                </DialogTitle>
              </DialogHeader>
              <div className="px-8 pb-8">
                <ScrollArea className="h-[60vh]">
                  <ScrollableTextWithHighlighting
                    fullText={userContent.content}
                    textToHighlight={chunkText}
                  />
                </ScrollArea>
              </div>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <div className="flex-grow min-h-0 overflow-hidden">
          {userContent.type === UserContentType.Pdf && presignedUrl && (
            <PdfViewer
              url={presignedUrl}
              pageNumber={pageNumber ?? 0}
              boundingBox={boundingBox}
              title={userContent.title}
            />
          )}
        </div>
      )}
    </div>
  ) : (
    <p className="text-sm font-bold leading-relaxed break-words">
      Source document not found.
    </p>
  );
}
