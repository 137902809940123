import { toast } from "sonner";

export function errorNotification(title: string, message?: string) {
  toast.error(title, {
    description: message,
  });
}

export function successNotification(title: string, message?: string) {
  toast.success(title, {
    description: message,
  });
}

export function playbookCompleteNotification(
  chatId: string,
  playbookName: string,
  isSuccess: boolean,
) {
  if (isSuccess)
    toast.success("Playbook Complete", {
      description: `Processing for "${playbookName}" is finished.`,
      duration: 10000,
      action: {
        label: "View",
        onClick: () => {
          window.location.href = `/c/${chatId}`;
        },
      },
    });
  else
    toast.error("Playbook Failed", {
      description: `Processing for "${playbookName}" has failed.`,
      duration: 10000,
      action: {
        label: "View",
        onClick: () => {
          window.location.href = `/c/${chatId}`;
        },
      },
    });
}
