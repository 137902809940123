import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useSetPriceForEstimate = () => {
  const { call } = useApiPrivateRequest<void>();

  const setNewPrice = async (price: number) => {
    await call(`/admin/playbook/estimate/price`, "PUT", {
      price,
    });
  };

  return {
    setNewPrice,
  };
};
