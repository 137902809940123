import { Route, Routes, useNavigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
// import DataChat from "./pages/DataChat";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import AuthProtectedRoute from "./shared/components/protected-route/AuthProtectedRoute";
import RoleProtectedRoute from "./shared/components/protected-route/RoleProtectedRoute";
import { UserRole } from "./shared/enums/UserRole";
import AdminDashboard from "./pages/AdminDashboard";
import AdminUserChats from "./pages/AdminUserChats";
import AdminChatLayer from "./pages/AdminChatLayer";
import * as amplitude from "@amplitude/analytics-browser";
import Transcript from "./pages/Transcript";
import Filing from "./pages/Filing";
import * as Sentry from "@sentry/react";
import { useLocalStorage } from "@mantine/hooks";
import { useEffect } from "react";
import Home from "./pages/Home";
import { ChatboxPosition, Crisp } from "crisp-sdk-web";
import { LocalStorageKeys } from "./shared/enums/LocalStorageKeys";
import CenterLoader from "./shared/components/loader/CenterLoader";
import Chat from "./pages/Chat";
import Report from "./pages/Report";
import Playbooks from "./pages/Playbooks";
import PlaybookEdit from "./pages/PlaybookEdit";
import AcceptPlaybook from "./pages/AcceptPlaybook";
import UserUpdatesComponent from "@/shared/components/signalr/UserUpdatesComponent";

export default function App() {
  if (
    !import.meta.env.VITE_API_BASE_URL.includes("localhost") &&
    !import.meta.env.DEV
  ) {
    Sentry.init({
      dsn: "https://37a8b0098cadbff85239a7497aa7fea9@o4506923228004352.ingest.us.sentry.io/4507533859946496",
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      integrations: [Sentry.browserTracingIntegration()],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "app.quantly-ai.com",
        "demo.quantly-ai.com",
      ],
    });
  }

  const { isLoading, user } = useAuth0();
  const [redirectUrl, , clearRedirectUrl] = useLocalStorage<string>({
    key: LocalStorageKeys.RedirectUrl,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!redirectUrl || !user) return;
    const currentUrl = redirectUrl;
    clearRedirectUrl();
    navigate(currentUrl);
  }, [redirectUrl, clearRedirectUrl, navigate, user]);

  useEffect(() => {
    if (import.meta.env.VITE_CRISP_WEBSITE_ID && user && user.email) {
      Crisp.configure(import.meta.env.VITE_CRISP_WEBSITE_ID);
      Crisp.user.setEmail(user.email);
      Crisp.setPosition(ChatboxPosition.Left);
    }
  }, [user]);
  if (user && user.sub) {
    if (ReactGA.isInitialized) ReactGA.set({ user: user.sub });
    if (hotjar.initialized()) hotjar.identify(user.sub, {});
    amplitude.setUserId(user.sub);
    if (user.email) {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set("email", user.email);
      amplitude.identify(identifyEvent);
    }
    // Set Sentry user context
    Sentry.setUser({
      id: user.sub,
      email: user.email,
    });
  } else {
    // Clear Sentry user context when no user is present
    Sentry.setUser(null);
  }

  if (isLoading) {
    return <CenterLoader />;
  }
  // if (import.meta.env.VITE_ADMIN_PANEL === "ON") {
  //   return (
  //     <div className="App">
  //       <Routes>
  //         <Route element={<RoleProtectedRoute role={UserRole.Admin} />}>
  //           <Route path="/" element={<AdminDashboard />} />
  //           <Route path="/admin" element={<AdminDashboard />} />
  //           <Route
  //             path="/admin/user/:userId/chats"
  //             element={<AdminUserChats />}
  //           />
  //           <Route path="/admin/chat/:chatId" element={<AdminChatLayer />} />
  //           <Route
  //             path="/admin/user/:userId/chat/:chatId"
  //             element={<AdminUserChats />}
  //           />
  //         </Route>
  //       </Routes>
  //     </div>
  //   );
  // } else
  return (
    <div className="App">
      <Routes>
        <Route element={<AuthProtectedRoute />}>
          <Route element={<UserUpdatesComponent />}>
            {import.meta.env.VITE_QUANTLY_MODE === "DOCS" && (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/c/:chatId" element={<Chat />} />
                <Route path="/c/:chatId/report" element={<Report />} />
                <Route path="/playbooks" element={<Playbooks />} />
                <Route path="/playbooks/:id/edit" element={<PlaybookEdit />} />
              </>
            )}

            {/* {import.meta.env.VITE_QUANTLY_MODE === "DATA" && (
              <>
                <Route path="/" element={<DataChat />} />
                <Route path="/m/:mode" element={<DataChat />} />
              </>
            )} */}

            <Route element={<RoleProtectedRoute role={UserRole.Admin} />}>
              <Route path="/admin" element={<AdminDashboard />} />
              <Route
                path="/admin/user/:userId/chats"
                element={<AdminUserChats />}
              />
              <Route path="/admin/chat/:chatId" element={<AdminChatLayer />} />
              <Route
                path="/admin/user/:userId/chat/:chatId"
                element={<AdminUserChats />}
              />
            </Route>
            <Route path="/transcript/:transcriptId" element={<Transcript />} />
            <Route path="/filing/:filingId" element={<Filing />} />
            <Route
              path="/playbook/accept/:token"
              element={<AcceptPlaybook />}
            />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}
