import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  BookOpenText,
  Bot,
  Check,
  ChevronDown,
  Copy,
  Lightbulb,
} from "lucide-react";
import { useRef, useState, useEffect } from "react";
import { useAdminView } from "../../../shared/hooks/useAdminView";
import { useHasAdminRole } from "../../../shared/hooks/useHasRole";
import { ChatShort } from "../../../shared/models/Chat";
import ChatInfo from "./ChatInfo";
import { ChatMessage } from "../models/ChatMessage";
import { UserInputMessageData } from "../models/message-data/UserInputMessageData";
import UserInputMessage from "./messages/UserInputMessage";
import { MessageDataType } from "../enums/MessageDataType";
import { ModelOutputMessageData } from "../models/message-data/ModelOutputMessageData";
import { ExecutionPlanNew } from "./ExecutionPlanNew";
import CenterLoader from "../../../shared/components/loader/CenterLoader";
import { useClipboard, useMediaQuery } from "@mantine/hooks";
import { isLargeScreenMediaQuery } from "../../../shared/utils/ResponsiveStyles";
import Timer from "../../../shared/components/timer/Timer";
import { MessageProcessor } from "../models/MessageDataProcessor";
import { Citation } from "../../sources/models/Citation";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ContentWithCitations } from "@/features/sources/models/ContentWithCitations";
import { errorNotification } from "@/shared/utils/Notifications";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import ScrollableText from "@/shared/components/scrollable-text/ScrollableText";

interface MessagesListProps {
  chat: ChatShort;
  messages: ChatMessage[];
  autoScroll?: boolean;
  onCitationClick?: (citation: Citation) => void;
  onSourcesClick?: (citations: Citation[]) => void;
}

export default function MessageList({
  messages,
  chat,
  autoScroll = true,
  onCitationClick,
  onSourcesClick,
}: MessagesListProps) {
  const isAdmin = useHasAdminRole();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [isAdminView] = useAdminView();
  const clipboard = useClipboard({ timeout: 2000 });

  const scrollToBottom = () => {
    if (!scrollRef.current || !autoScroll) return;

    scrollRef.current.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: "instant",
    });
    setIsAtBottom(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const isUserAtBottom =
        Math.abs(scrollHeight - scrollTop - clientHeight) < 10;
      setIsAtBottom(isUserAtBottom);
    };

    const scrollElement = scrollRef.current;
    scrollElement?.addEventListener("scroll", handleScroll);

    // Only scroll to bottom on mount if autoScroll is true
    if (autoScroll) {
      setTimeout(scrollToBottom, 5);
    }

    return () => scrollElement?.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoScroll]);

  const isLargeScreen = useMediaQuery(isLargeScreenMediaQuery);

  const handleSourcesClick = (message: ChatMessage) => {
    if (message.data?.type !== MessageDataType.ModelOutputMessageData) return;
    const modelOutputData = message.data as ModelOutputMessageData;
    const contentWithCitations = new ContentWithCitations(
      modelOutputData.content,
    );
    if (!contentWithCitations.citations.length) {
      errorNotification("No sources were used");
      return;
    }

    onSourcesClick?.(contentWithCitations.citations);
  };

  return (
    <div className="relative flex-grow flex flex-col overflow-hidden">
      <ScrollArea className="flex-grow overflow-hidden" ref={scrollRef}>
        <div className="space-y-6 px-2 pt-4">
          {isAdmin && isAdminView && chat && <ChatInfo chat={chat} />}
          <div className="space-y-6">
            {messages.map((message, i) => (
              <div key={message.id} className="px-2">
                {message.error && i === messages.length - 1 ? (
                  <div className="space-y-2">
                    <Card className="flex-1 p-4">
                      <div className="flex items-start gap-4">
                        <Bot className="h-5 w-5 flex-shrink-0 text-foreground" />
                        <div className="flex-1 min-w-0">
                          {isAdmin && isAdminView && (
                            <div className="flex justify-between items-center mb-2">
                              <p className="text-sm text-muted-foreground">
                                {message.data?.type}
                              </p>
                              <Timer
                                startedAt={message.startedAt}
                                finishedAt={message.finishedAt}
                                variant="neutral"
                              />
                            </div>
                          )}
                          <div className="text-sm text-destructive">
                            {message.error}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                ) : !message.data ? (
                  <CenterLoader size="sm" />
                ) : (
                  (() => {
                    const data = message.data;
                    const type = data.type;
                    const functionCalls = (data as ModelOutputMessageData)
                      .functionCalls;

                    if (type === MessageDataType.UserInputMessageData) {
                      return (
                        <UserInputMessage
                          key={message.id}
                          data={data as UserInputMessageData}
                          chatId={chat.id}
                        />
                      );
                    }

                    if (
                      (type === MessageDataType.ModelOutputMessageData &&
                        !functionCalls) ||
                      type === MessageDataType.GetMarketDataMessageData ||
                      type === MessageDataType.ExecuteCodeMessageData
                    ) {
                      return (
                        <div className="space-y-2">
                          <Card className="flex-1 p-4">
                            <div className="flex items-start gap-4">
                              <Bot className="h-5 w-5 flex-shrink-0 text-foreground" />
                              <div className="flex-1 min-w-0">
                                {isAdmin && isAdminView && (
                                  <div className="flex justify-between items-center mb-2">
                                    <p className="text-sm text-muted-foreground">
                                      {data.type}
                                    </p>
                                    <Timer
                                      startedAt={message.startedAt}
                                      finishedAt={message.finishedAt}
                                      variant="neutral"
                                    />
                                  </div>
                                )}
                                {MessageProcessor.GetComponent(
                                  data,
                                  message.inProgress,
                                  onCitationClick,
                                )}
                                {message.error && (
                                  <div className="mt-2 text-sm text-destructive">
                                    {message.error}
                                  </div>
                                )}
                              </div>
                            </div>
                            <Tooltip delayDuration={100}>
                              <TooltipTrigger>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="text-gray-400 hover:text-gray-600 ml-6"
                                  onClick={() => {
                                    if (
                                      message.data?.type !==
                                      MessageDataType.ModelOutputMessageData
                                    )
                                      return;
                                    const modelOutputData =
                                      message.data as ModelOutputMessageData;
                                    const content = modelOutputData.content;
                                    clipboard.copy(content);
                                  }}
                                >
                                  {!clipboard.copied ? <Copy /> : <Check />}
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent side="bottom">
                                Copy
                              </TooltipContent>
                            </Tooltip>
                            <Tooltip delayDuration={100}>
                              <TooltipTrigger>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="text-gray-400 hover:text-gray-600"
                                  onClick={() => handleSourcesClick(message)}
                                >
                                  <BookOpenText />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent side="bottom">
                                Sources
                              </TooltipContent>
                            </Tooltip>
                            {data.type ===
                              MessageDataType.ModelOutputMessageData &&
                              (data as ModelOutputMessageData).reasoning && (
                                <Popover modal={false}>
                                  <PopoverTrigger>
                                    <Tooltip delayDuration={100}>
                                      <TooltipTrigger>
                                        <Button
                                          variant="ghost"
                                          size="icon"
                                          className="text-gray-400 hover:text-gray-600"
                                        >
                                          <Lightbulb />
                                        </Button>
                                      </TooltipTrigger>
                                      <TooltipContent side="bottom">
                                        Reasoning
                                      </TooltipContent>
                                    </Tooltip>
                                  </PopoverTrigger>
                                  <PopoverContent className="w-[500px] h-full rounded-md">
                                    <ScrollableText
                                      content={
                                        (data as ModelOutputMessageData)
                                          .reasoning
                                      }
                                    />
                                  </PopoverContent>
                                </Popover>
                              )}
                          </Card>
                        </div>
                      );
                    }

                    // Find only function calls after user message
                    const isPreviousUserMessage =
                      messages[i - 1]?.data?.type ===
                      MessageDataType.UserInputMessageData;
                    if (!isPreviousUserMessage) return null;

                    const executionPlanMessages = [] as ChatMessage[];
                    // Find all function calls before next model output without function call
                    const nextModelOutputMessageIndex = messages
                      .slice(i)
                      .findIndex((m) => {
                        return (
                          m.data?.type ===
                            MessageDataType.ModelOutputMessageData &&
                          !(m.data as ModelOutputMessageData).functionCalls
                        );
                      });

                    if (nextModelOutputMessageIndex === -1) {
                      executionPlanMessages.push(...messages.slice(i));
                    } else {
                      executionPlanMessages.push(
                        ...messages.slice(i, i + nextModelOutputMessageIndex),
                      );
                    }

                    if (executionPlanMessages.length !== 0)
                      return (
                        <div className="w-full">
                          <ExecutionPlanNew
                            key={message.id}
                            messages={executionPlanMessages}
                          />
                        </div>
                      );
                  })()
                )}
              </div>
            ))}
          </div>
        </div>
      </ScrollArea>

      {!isAtBottom && (
        <Button
          variant="outline"
          size={isLargeScreen ? "default" : "sm"}
          className="absolute right-1/2 translate-x-1/2 bottom-4 shadow-md"
          onClick={scrollToBottom}
        >
          <ChevronDown className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
}
