import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  IconZoomIn,
  IconZoomOut,
  IconChevronUp,
  IconChevronDown,
  IconMaximize,
} from "@tabler/icons-react";

// Initialize PDF.js worker once at module level
const initializeWorker = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
};
initializeWorker();

interface PdfViewerProps {
  url: string;
  pageNumber: number;
  boundingBox?: {
    xMin: number; // Value between 0-1000 representing relative position
    yMin: number; // Value between 0-1000 representing relative position
    xMax: number; // Value between 0-1000 representing relative position
    yMax: number; // Value between 0-1000 representing relative position
  };
  title?: string;
}

export function PdfViewer({
  url,
  pageNumber: initialPageNumber, // starting with 1 as pages are 1-indexed
  boundingBox,
  title,
}: PdfViewerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(initialPageNumber + 1); // starting with 1 as pages are 1-indexed
  const [numPages, setNumPages] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);
  const [scale, setScale] = useState(1.0);
  const [baseScale, setBaseScale] = useState(1.0);
  const [pageHeight, setPageHeight] = useState<number>(0);
  const [pageWidth, setPageWidth] = useState<number>(0);
  const [inputValue, setInputValue] = useState(`${currentPage}`);
  const [initialScaleSet, setInitialScaleSet] = useState(false);

  const scaleText = useMemo(
    () => `${Math.round((scale / baseScale) * 100)}%`,
    [scale, baseScale],
  );
  const zoomInEnabled = useMemo(
    () => scale / baseScale < 2.0,
    [scale, baseScale],
  );
  const zoomOutEnabled = useMemo(
    () => scale / baseScale > 0.5,
    [scale, baseScale],
  );

  const options = useMemo(
    () => ({
      cMapUrl: "https://unpkg.com/pdfjs-dist@3.11.174/cmaps/",
      cMapPacked: true,
      standardFontDataUrl:
        "https://unpkg.com/pdfjs-dist@3.11.174/standard_fonts/",
    }),
    [],
  );

  const calculateFitScale = useCallback(() => {
    if (containerRef.current && pageWidth) {
      const containerWidth = containerRef.current.clientWidth;
      return (containerWidth - 32) / pageWidth;
    }
    return 1.0;
  }, [pageWidth]);

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (initialScaleSet) {
        const newBaseScale = calculateFitScale();
        setBaseScale(newBaseScale);
        // Preserve the current zoom ratio when updating scale
        setScale((prev) => (prev / baseScale) * newBaseScale);
      }
    });

    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, [calculateFitScale, initialScaleSet, baseScale]);

  const fitToWidth = useCallback(() => {
    const newScale = calculateFitScale();
    setScale(newScale);
    setBaseScale(newScale);
  }, [calculateFitScale]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    setError(null);
  }

  function onDocumentLoadError(err: Error): void {
    setError(err);
  }

  const goToNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
      setInputValue(`${currentPage + 1}`);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setInputValue(`${currentPage - 1}`);
    }
  };

  const handleZoomIn = () => {
    if (zoomInEnabled) {
      setScale((prev) => prev * 1.25);
    }
  };

  const handleZoomOut = () => {
    if (zoomOutEnabled) {
      setScale((prev) => prev * 0.8);
    }
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handlePageInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const value = parseInt(inputValue, 10);
      if (!isNaN(value) && value > 0 && value <= numPages) {
        setCurrentPage(value);
      } else {
        setInputValue(`${currentPage}`);
      }
    }
  };

  if (error) {
    return (
      <div className="text-red-500">
        Error loading PDF. Please try again later. {error.message}
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col w-full border-b flex-none">
        {title && (
          <div className="px-3 py-1.5 border-b">
            <div
              className="font-medium text-sm break-words hover:cursor-help"
              title={title}
            >
              {title}
            </div>
          </div>
        )}
        <div className="flex h-[36px] w-full items-center justify-between px-4">
          <div className="flex w-[120px] text-gray-900">
            <button
              className="p-0.5 enabled:hover:rounded enabled:hover:bg-gray-100 disabled:text-gray-300"
              onClick={goToPreviousPage}
              disabled={currentPage <= 1}
            >
              <IconChevronUp size={16} />
            </button>
            <div className="flex items-center justify-center">
              <input
                className="ml-1 h-[22px] w-[32px] rounded border py-1 px-0.5 text-left text-sm focus:outline-none"
                value={inputValue}
                onChange={handlePageInputChange}
                onKeyDown={handlePageInputKeyDown}
              />
            </div>
            <div className="ml-1 text-sm"> / {numPages}</div>
            <button
              className="ml-1 p-0.5 enabled:hover:rounded enabled:hover:bg-gray-100 disabled:text-gray-300"
              onClick={goToNextPage}
              disabled={currentPage >= numPages}
            >
              <IconChevronDown size={16} />
            </button>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center justify-between gap-2">
              <button
                className="p-0.5 text-gray-900 enabled:hover:rounded enabled:hover:bg-gray-100 disabled:text-gray-600"
                onClick={handleZoomOut}
                disabled={!zoomOutEnabled}
                title="Zoom out"
              >
                <IconZoomOut size={18} />
              </button>
              <div className="w-[48px] text-center text-sm">{scaleText}</div>
              <button
                className="p-0.5 text-gray-900 enabled:hover:rounded enabled:hover:bg-gray-100 disabled:text-gray-600"
                onClick={handleZoomIn}
                disabled={!zoomInEnabled}
                title="Zoom in"
              >
                <IconZoomIn size={18} />
              </button>
              <div className="mx-1 h-5/6 w-px bg-gray-300"></div>
              <button
                className="p-0.5 text-gray-900 enabled:hover:rounded enabled:hover:bg-gray-100"
                onClick={fitToWidth}
                title="Fit to width"
              >
                <IconMaximize size={18} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-grow overflow-hidden bg-white shadow">
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          loading={
            <div className="flex items-center justify-center h-full">
              <div className="animate-pulse text-primary text-sm">
                Loading PDF...
              </div>
            </div>
          }
          noData={
            <div className="flex items-center justify-center h-full">
              <div className="animate-pulse text-primary text-sm">
                No PDF file specified.
              </div>
            </div>
          }
          options={options}
          className="h-full"
        >
          <div ref={containerRef} className="h-full overflow-auto">
            <div className="flex justify-center min-h-full">
              <div className="relative p-2">
                <Page
                  pageNumber={currentPage}
                  renderAnnotationLayer={false}
                  scale={scale}
                  onLoadSuccess={(page) => {
                    if (!initialScaleSet) {
                      setPageWidth(page.originalWidth);
                      setPageHeight(page.originalHeight);
                      const newScale = calculateFitScale();
                      setScale(newScale);
                      setBaseScale(newScale);
                      setInitialScaleSet(true);
                    } else {
                      setPageWidth(page.originalWidth);
                      setPageHeight(page.originalHeight);
                    }
                  }}
                />
                {boundingBox &&
                  pageWidth &&
                  pageHeight &&
                  currentPage === initialPageNumber + 1 && (
                    <div
                      className="absolute bg-yellow-300/30 pointer-events-none"
                      style={{
                        left: (boundingBox.xMin / 1000) * pageWidth * scale,
                        top: (boundingBox.yMin / 1000) * pageHeight * scale,
                        width:
                          ((boundingBox.xMax - boundingBox.xMin) / 1000) *
                          pageWidth *
                          scale,
                        height:
                          ((boundingBox.yMax - boundingBox.yMin) / 1000) *
                          pageHeight *
                          scale,
                      }}
                    />
                  )}
              </div>
            </div>
          </div>
        </Document>
      </div>
    </div>
  );
}
