import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useGetPriceForEstimate = () => {
  const {
    data: price,
    call,
    setData: setPrice,
  } = useApiPrivateRequest<number>((response) => parseFloat(response));

  const getPrice = async () => {
    await call(`/admin/playbook/estimate/price`, "GET");
  };

  return {
    price,
    getPrice,
    setPrice,
  };
};
