import { ScrollArea } from "@/components/ui/scroll-area";
import classes from "./styles/ScrollableText.module.css";

interface ScrollableTextProps {
  content?: string;
}

export default function ScrollableText({ content }: ScrollableTextProps) {
  return (
    <ScrollArea
      className={`${classes["scrollarea"]} text-sm leading-relaxed prose prose-sm max-w-none prose-p:leading-relaxed prose-pre:my-0`}
    >
      <p className={classes["text"]}>{content}</p>
    </ScrollArea>
  );
}
